import React from 'react'
import { 
    Box,
    Grid,
    styled,
    Typography,
} from '@mui/material'
import Title from './Title'
// img
import imgDetail from '../assets/222.png';
import imgDetail2 from '../assets/333.png';


const GetStarted = () => {

    const CustomGridItem = styled(Grid) ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    })
    
    const CustomTypography = styled(Typography) ({
        fontSize: '1.1rem',
        textAlign: 'start',
        lineHeight: '1.5',
        color: '#515151',
        marginTop: '1.5rem',
    })

    return (
            
        <Grid container spacing={{ xs: 4, sm: 4, md: 0 }}   
        sx={{
            py: 10,
            px: 2,
             
        }}
        >
            <CustomGridItem item xs={12} sm={8} md={6} 
            component = 'section'
           
            >
                <Box component='article'
                sx={{
                    px: 4,
                }}
                >
                    <Title
                    text={
                        'Sustainable Investment'
                    }
                    textAlign={'start'}
                    />
                    <CustomTypography>
                        For us, sustainability is not just a feature—it's our foundation.<br />
                        We understand the profound impact our projects have on communities <br />
                        and the environment. That’s why we are commited to sustainable <br />
                        property development at every level of our operations. <br /> 
                        Our approach includes the use of sustainable materials, energy-saving <br />
                        technologies, and cutting-edge designs that not only respect the <br />
                        environment but enhance it. We strive to set new standards in <br />
                        eco-friendly living, ensuring that every project we undertake is as <br />
                        green as it is groundbreaking.
                    </CustomTypography> 
                </Box>

            </CustomGridItem>
            
            <Grid item xs={12} sm={4} md={6}>
                <img src={imgDetail} alt="" 
                style={{
                    width: '100%',
                }}
                />
            </Grid>

            <Grid item xs={12} sm={4} md={6}
            sx={{
                order: {xs: 4, sm: 4, md: 3}
            }}
            >
                <img src={imgDetail2} alt="" 
                style={{ 
                    width: "100%",
                }}
                />
            </Grid>

            <CustomGridItem item xs={12} sm={8} md={6}
            sx={{
                order: {xs: 3, sm: 3, md: 4}
            }}
            >
                <Box component='article'
                sx={{
                    px: 4,
                }}
                >
                    <Title
                    text={
                        'Precision and Detail'
                        
                    }
                    textAlign={'start'}
                    />
                    <CustomTypography>
                        At Earl and Countess Group, our devotion to excellence <br />
                        is evident in every aspect of our real estate projects. <br />
                        From comprehensive master planning to the refined nuances <br />
                        of interior design, we ensure that every detail is crafted <br />
                        with utmost precision. Our unwavering commitment to quality <br />
                        transforms each development into a landmark of construction <br />
                        and architectural distinction, setting new standards in the <br />
                        real estate industry.
                    </CustomTypography>
                </Box>
            </CustomGridItem>
        </Grid>
    )
}

export default GetStarted;