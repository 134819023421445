import React from 'react'
import { 
  Box, 
  Stack, 
  styled, 
  Typography,
} from '@mui/material'
import Link from '@mui/material/Link';
import FooterTitle from './FooterTitle'
import FooterLink from './FooterLink'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import XIcon from '@mui/icons-material/X';

const Footer = () => {

  const StackColumn = styled(Stack)(() => ({
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flex: 1,
    gap: 8,
    textAlign: 'center',
  }));

  const BoxRow = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#ededed',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: 30,
    }
  }));

  const lowercaseTextStyle = {
    textTransform: 'lowercase',
  };

  return (
    <BoxRow 
      component='footer'
      sx={{
        py: 4,
        px: 2,
      }}
    >
      <StackColumn>
        <FooterTitle text={'address'} sx={lowercaseTextStyle} />
        <FooterLink 
          text= 'Plot 706, Zone B, Apo Resettlement, FCT, Abuja, Nigeria.' 
          sx={lowercaseTextStyle}
        />
        <FooterLink 
          text={''} 
          sx={lowercaseTextStyle}
        />
        <FooterLink 
          text='kayode@earlandcountess.org' 
          sx={lowercaseTextStyle}
        />
      </StackColumn>
      
      <StackColumn>
        <FooterTitle text={'our services'} sx={lowercaseTextStyle} />
        <FooterLink text={'Buy properties'} sx={lowercaseTextStyle} />
        <FooterLink text={'Sell properties'} sx={lowercaseTextStyle} />
        <FooterLink text={'Rent properties'} sx={lowercaseTextStyle} />
        <FooterLink text={'Build properties'} sx={lowercaseTextStyle} />
      </StackColumn>

      <StackColumn>
        <FooterTitle text={'our company'} sx={lowercaseTextStyle} />
        <FooterLink text={'reporting'} sx={lowercaseTextStyle} />
        <FooterLink text={'get in touch'} sx={lowercaseTextStyle} />
        <FooterLink text={'management'} sx={lowercaseTextStyle} />
      </StackColumn>

      <StackColumn>
        <FooterTitle text={'Earl & Countess'} sx={lowercaseTextStyle} />
        <Stack 
          direction='row' 
          width='70px'
          maxWidth='100%'
          justifyContent='space-between'
        >
          <Link href="#" variant="body2" 
            sx={{
              color: '#414141',
              "&:hover": {
                color: '#1c2859',
              }
            }}
          >
            <InstagramIcon />  
          </Link> 
          <Link href="#" variant="body2" 
            sx={{
              color: '#414141',
              "&:hover": {
                color: '#1c2859',
              }
            }}
          >
            <LinkedInIcon />  
          </Link> 
          <Link href="#" variant="body2" 
            sx={{
              color: '#414141',
              "&:hover": {
                color: '#1c2859',
              }
            }}
          >
            <XIcon />  
          </Link> 
          <Link href="#" variant="body2" 
            sx={{
              color: '#414141',
              "&:hover": {
                color: '#1c2859',
              }
            }}
          >
            <FacebookIcon />
          </Link> 
        </Stack>
        <Typography 
          variant='caption'
          component='p' 
          sx={lowercaseTextStyle}
        >
          &copy; 2024 Earl & Countess Group.
        </Typography>
      </StackColumn>
    </BoxRow>
  )
}

export default Footer
